import React, { Component } from 'react';
import style from 'styled-components';
import lupa from '../../images/pesquisar.png';
import api2 from '../../api2';

class Pesquisar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tema: []
    };
  }

  componentDidMount() {
    api2.get('/home/topo').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }

  render() {
    const AreaPesquisar = style.div`
      margin-right: -17px;
      align-items: center;
      display: flex;
      max-width: 210px;
    `;

    const Input = style.input`
      margin-right: 5px;
      padding: 7px;
      border-radius: 5px;
      border: medium none;
      width: 70%;
    `;

    const Button = style.button`
      background: ${this.state.tema.btn_primario};
      border: medium none;
      border-radius: 5px;
      padding: 5px 5px 0px;
      width: 40px;
      position: relative;
    `;

    return (
      <AreaPesquisar>
        <form
          action="/filter"
          style={{ textAlign: 'center', display: 'inline-flex' }}
        >
          <Input
            placeholder="Buscar produto"
            id="filtrar"
            type="text"
            name="pesquisa"
          />
          <Button>
            <img src={lupa} alt="lupa" width="20px" />
          </Button>
        </form>
      </AreaPesquisar>
    );
  }
}

export default Pesquisar;
