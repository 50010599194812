import React from 'react';
import style from 'styled-components';
import QrReader from 'react-qr-reader';

const Message = style.h2`
  text-align: center;
  font-weight: 400;
  font-size: 28px;
`;

const QRScanner = props => {
  const handleScan = data => {
    if (data) {
      const regex = /https?:\/\/(store|loja).inovesistemas.info\/produto\/(\d+)/i;
      const matches = data.match(regex);

      if (matches && matches[2]) {
        props.onScan(`/produto/${matches[2]}`);
      } else {
        alert('Código inválido, por favor tente novamente!');
      }
    }
  };

  const handleError = err => {
    console.error(err);
  };

  return (
    <>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={props.style}
      />
      <Message>Aponte para o QR Code</Message>
    </>
  );
};

export default QRScanner;
