import React, { Component } from 'react';
import style from 'styled-components';
import { Container } from '../grid';
import QRModal from '../qr-modal';
import LogoInfo from '../logo-info';
import Wpp from '../wpp';
import Pesquisar from '../pesquisar';
import api2 from '../../api2';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tema: [],
      pesquisa: [],
      qrcode: [],
      whatsapp: []
    };
  }

  componentDidMount() {
    api2.get('/home/topo').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
    api2.get('/home/itenstopo').then(res => {
      this.setState({
        pesquisa: res.data.response[0],
        qrcode: res.data.response[1],
        whatsapp: res.data.response[2]
      });
    });
  }

  render() {
    const Nav = style.nav`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: ${this.state.tema.cor_primario};
        z-index: 1000;
      `;

    const NavContainer = style(Container)`
        display: flex;
        justify-content: space-between;
        padding: 15px;
      `;
    return (
      <Nav>
        <NavContainer>
          <div>
            <LogoInfo />
          </div>
          <div style={{ display: 'inline-flex' }}>
            {this.state.pesquisa.exibir == 1 ? <Pesquisar /> : ''}
            {this.state.qrcode.exibir == 1 ? <QRModal /> : ''}
            {this.state.whatsapp.exibir == 1 ? <Wpp /> : ''}
          </div>
        </NavContainer>
      </Nav>
    );
  }
}

export default Navbar;
