export const formatMoney = number => {
  const regex = /(\d)(?=(\d{3})+,)/g;
  const strNumber = parseFloat(number).toFixed(2);
  const money = strNumber.replace('.', ',').replace(regex, '$1.');
  return `R$ ${money}`;
};

export const getInstallments = value => {
  let available = false;
  let quantity = 0;

  if (value >= 50) {
    available = true;

    if (value < 100) {
      quantity = 2;
    } else {
      quantity = 6;
    }
  }

  return {
    available,
    quantity,
    value: value / quantity
  };
};
