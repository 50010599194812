import React, { Component } from 'react';
import style from 'styled-components';
import api2 from '../../api2';

class Legend extends Component {
  constructor(props) {
    super(props);
    this.state = { tema: [] };
  }

  componentDidMount() {
    api2.get('/home/topo').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }

  render() {
    const Wrapper = style.legend`
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
      position: relative;
      text-align: center;

      &:first-child {
        margin-top: 0;
      }

      > span {
        display: inline-block;
        padding: 15px 30px;
        color: #fff;
        background: ${this.state.tema.cor_primario};
        border-radius: 100px;
      }

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        background: ${this.state.tema.cor_primario};
        z-index: -1;
      }
    `;
    return (
      <Wrapper>
        <span>{this.props.nome}</span>
      </Wrapper>
    );
  }
}

export default Legend;
