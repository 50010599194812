import React, { Component } from "react";
import style from "styled-components";
import Slider from "react-slick";
import { Container } from "../grid";
import api from "../../api";
import api2 from "../../api2";
import "./categoria-bar.scss";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: false,
  autoplay: false,
  slidesToShow: 9,
  slidesToScroll: 9,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 8,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

class CategoriesBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: [],
      tema: [],
      catego: [],
    };
  }

  componentDidMount() {
    api.get("/categoria").then(res => {
      this.setState({
        categoria: res.data,
      });
    });
    api2.get("/home/topo").then(res => {
      this.setState({
        tema: res.data.response,
      });
    });
    api2.get("/home/itenstopo").then(res => {
      this.setState({
        catego: res.data.response[3],
      });
    });
  }

  menuSubOn(id) {
    var element = document.getElementById(id);
    element.classList.add(`submenufixo${id}`);
  }
  menuSubOff(id) {
    var element = document.getElementById(id);
    element.classList.remove(`submenufixo${id}`);
  }
  menuSubClick(id) {
    var element = document.getElementById(id);
    element.classList.toggle(`submenufixo${id}`);
  }

  render() {
    const Bar = style.div`
      width: 100%;
      position: fixed;
      top: 66px;
      left: 0;
      right: 0;
      background: ${this.state.tema.cor_primario};
      z-index: 999;
      height: 75px;
    `;
    const CategoryWrapper = style.div`
      display: flex;
      width: 115px;
      height: 64px;
      margin: 5px;
      vertical-align: middle;
      font-size: 0.8rem;
      perspective: 1000px;


    `;

    const CategoryContent = style.div`
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
      cursor: pointer;
      transition: transform 0.6s;
      transform-style: preserve-3d;

      > .front,
      > .back {
        display: block;
        -moz-box-pack: center;
        justify-content: center;
        -moz-box-align: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 4px;
        font-size: 11px;
      }

      > .back {
      }

      > .front {
        color: #333;
        background-color: ${this.state.tema.btn_primario};
        z-index: 2;
        display: block;
        text-align: center;
        transition: background-color 0.5s ease 0s, color 0.5s ease 0s;

        &:hover {
            background-color: ${this.state.tema.btn_secundario}
            transition: background-color 0.5s ease 0s, color 0.5s ease 0s;
        }

        > img {
          max-width: 32px;
          max-height: 32px;
          margin: auto;
          margin-top: 10px;
          padding-bottom:
        }
      }

    `;
    return (
      <Bar>
        <Container>
          <Slider {...settings}>
            {this.state.categoria.map(category => (
              <div
                onMouseOver={() => this.menuSubOn(category.id)}
                onMouseOut={() => this.menuSubOff(category.id)}
                onClick={() => this.menuSubClick(category.id)}
              >
                <div key={category.id}>
                  <CategoryWrapper title={category.nome_categoria}>
                    <CategoryContent>
                      <div className="front">
                        {this.state.catego.exibir == 1 ? (
                          <img
                            src={category.icone_categoria}
                            alt="Category Icon"
                          />
                        ) : (
                          ""
                        )}{this.state.catego.exibir == 1 ? (
                        <div className="back">{category.nome_categoria}</div>
                        ): (<div className="back" style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>{category.nome_categoria}</div>)}
                      </div>
                    </CategoryContent>
                  </CategoryWrapper>
                </div>
                <div className="menusubtamanho">
                  <div
                    className={`menusubcategoria${category.id}`}
                    style={{ background: this.state.tema.cor_primario }}
                    id={category.id}
                  >
                    <a
                      href={`/categoria/?categoria=${category.id}`}
                      target="blank"
                    >
                      <h3 style={{ fontSize: "14px", marginLeft: "10px" }}>
                        {category.nome_categoria}
                      </h3>
                    </a>
                    <ul>
                      {category.subcategoria.map(sub => (
                        <a
                          href={`/subcategoria/?subcategoria=${sub.id}`}
                          target="blank"
                        >
                          <li style={{ fontSize: "14px", margin: "5px" }}>
                            {sub.nome}
                          </li>
                        </a>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </Bar>
    );
  }
}

export default CategoriesBar;
