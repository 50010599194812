import React from 'react';
import style from 'styled-components';
import { ElementWrapper, Label, ElementBase } from './form';

const Element = style.textarea`
  ${ElementBase};
  resize: none;
`;

const Textarea = props => (
  <ElementWrapper>
    <Label htmlFor={props.id}>{props.text}</Label>
    <Element
      id={props.id}
      name={props.name}
      rows={props.rows}
      onChange={props.onChange}
      value={props.value}
      required
    />
  </ElementWrapper>
);

export default Textarea;
