import React, { Component } from 'react';
import style from 'styled-components';

class Cod extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const Cod = style.div`
      padding: 3px 10px;
      margin: auto;
      position: absolute;
      top: 10px;
      left: 10px;
      color: rgb(44, 44, 44);
      background: rgb(233, 232, 232) none repeat scroll 0% 0%;
      border-radius: 100px;
      font-size: 10px;
    `;
    return <Cod>Cód.: {this.props.cod}</Cod>;
  }
}

export default Cod;
