import React, { Component } from 'react';
import style from 'styled-components';
import renderHTML from 'react-render-html';
import { Container, Row, Col } from '../grid';
import Social from '../social';
import LogoInove from '../logo-inove';
import Mapa from '../mapa/mapa.js';
import PaymentForm from '../payment-form';
import GlobalSign from '../../images/global-sign.png';
import Google from '../../images/google.png';
import Newsletter from '../newsletter';
import api from '../../api';
import api2 from '../../api2';

const FooterWrapper = style.footer`
  padding: 30px;
  border-top: #eee 1px solid;
  background: #f8fafd;

  @media (max-width: 799px) {
    text-align: center;
  }

  @media (min-width: 800px) {
    .col-right {
      text-align: right;
    }
  }
`;

const FooterTitle = style.h3`
  font-weight: normal;
`;

const Certificate = style.a`
  margin: 10px;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endereco: '',
      mapa: [],
      news: [],
      cartoes: [],
      social: [],
      certificadoGoogle: [],
      certificado: []
    };
  }
  componentDidMount() {
    api.get('/endereco').then(res => {
      this.setState({
        endereco: res.data.texto
      });
    });
    api.get('/social').then(res => {
      this.setState({
        social: res.data
      });
    });
    api2.get('/home/mapa').then(res => {
      this.setState({
        mapa: res.data.response
      });
    });
    api2.get('/home/news').then(res => {
      this.setState({
        news: res.data.response
      });
    });
    api2.get('/home/cartoes').then(res => {
      this.setState({
        cartoes: res.data.response
      });
    });
    api2.get('/home/certificado').then(res => {
      this.setState({
        certificadoGoogle: res.data.response[0],
        certificado: res.data.response[1]
      });
    });
  }
  render() {
    return (
      <FooterWrapper>
        <Container>{this.state.mapa.exibir != 0 ? <Mapa /> : ''}</Container>
        {this.state.news.exibir != 0 ? <Newsletter /> : ''}
        <Container>
          <Row>
            <Col>
              <div>
                {this.state.cartoes != '' ? (
                  <div style={{ maxWidth: '300px' }}>
                    <FooterTitle>Formas de pagamento</FooterTitle>
                    {this.state.cartoes.map(cartao => (
                      <img
                        style={{ width: '60px', margin: '5px' }}
                        src={cartao.url}
                        alt={cartao.nome}
                        title={cartao.nome}
                      />
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <br />
              <div>
                {this.state.certificado.exibir != 0 &&
                this.state.certificadoGoogle.exibir != 0 ? (
                  <FooterTitle>Certificação e segurança</FooterTitle>
                ) : (
                  ''
                )}
                <div>
                  {this.state.certificadoGoogle.exibir != 0 ? (
                    <Certificate
                      href={this.state.certificadoGoogle.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={GlobalSign} alt="Site seguro" />
                    </Certificate>
                  ) : (
                    ''
                  )}
                  {this.state.certificado.exibir != 0 ? (
                    <Certificate
                      href={this.state.certificado.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Google} alt="Site seguro" />
                    </Certificate>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-right">
              <br />
              <div>{renderHTML(this.state.endereco)}</div>
              <br />
              <div>
                {this.state.social.map(social =>
                  social.value != '' ? (
                    <Social name={social.name}>{social.value}</Social>
                  ) : (
                    ''
                  )
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{ textAlign: 'center', marginTop: '25px' }}>
          <LogoInove />
        </div>
      </FooterWrapper>
    );
  }
}

export default Footer;
