import React, { Component } from 'react';
import style from 'styled-components';
import { Container } from '../grid';
import api from '../../api';
import api2 from '../../api2';

class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tema: [],
      imei: ''
    };

    this.cadastrar = this.cadastrar.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
  }

  cadastrar() {
    api
      .get('/subscribe/', {
        params: {
          email: this.state.imei
        }
      })
      .then(res => {
        alert(res.data.message);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  HandleChange(e) {
    this.state.imei = e.target.value;
  }

  componentDidMount() {
    api2.get('/home/baixo').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }

  render() {
    const NewsArea = style.div`
      background: ${this.state.tema.cor_primario};
      margin-left: -30px;
      margin-right: -30px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      h2 {
        color: ${this.state.tema.cor_letra};
        width: 290px;
        margin-right: 70px;
      }
      form {
        margin-top: 40px;
      }

      @media (max-width: 800px) {
        h2 {
          margin: auto;
        }
      }

    `;

    const InputNews = style.input`
      width: 280px;
      margin-left: -10px;
      padding: 10px;
      margin-bottom: 20px;
      border-color: currentcolor currentcolor rgba(85, 85, 85, 0.45);
      border-style: none none solid;
      border-width: medium medium 2px;
      transition: border 500ms ease 0s;
      color: ${this.state.tema.cor_letra};
      background: ${this.state.tema.cor_primario}
      font-size: 14px;

      &:focus{
        transition: border 500ms ease 0s;
        border-color: ${this.state.tema.btn_primario};
      }

      @media (max-width: 800px) {
        width: 230px
      }

    `;
    const SubareaNews = style.div`
        display: flex;
        justify-content: flex-end;

        @media (max-width: 800px) {
          display: inline-block;
        }
    `;
    const ButtonNews = style.button`
      color: rgb(44, 44, 44);
      padding: 10px;
      position: relative;
      top: 2px;
      background: ${this.state.tema.btn_primario};
      border: medium none;
      margin-bottom: 20px;
      border-radius: 7px;
    `;

    return (
      <NewsArea>
        <Container>
          <SubareaNews>
            <div>
              <h2>Receba nossas novidades em primeira mão:</h2>
            </div>
            <div>
              <form action="#">
                <InputNews
                  onChange={this.HandleChange}
                  placeholder="Email"
                  type="email"
                />
                <ButtonNews onClick={() => this.cadastrar(this.state.imei)}>
                  Cadastrar
                </ButtonNews>
              </form>
            </div>
          </SubareaNews>
        </Container>
      </NewsArea>
    );
  }
}

export default Newsletter;
