import React from 'react';
import style from 'styled-components';

const Wrapper = style.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0, .6);
  z-index: 3000;
`;

const Spinner = style.div`
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  }

  > .bounce1 {
    animation-delay: -0.32s;
    background: #fecd04;
  }

  > .bounce2 {
    animation-delay: -0.16s;
    background: #11aeac;
  }

  > .bounce3 {
    background: #fff;
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1.0);
    }
  }
`;

const Loading = () => (
  <Wrapper>
    <Spinner>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </Spinner>
  </Wrapper>
);

export default Loading;
