import React, { Component } from "react";
import style from "styled-components";
import { formatMoney, getInstallments } from "../../tools";

class ProductPrice extends Component {
  constructor(props) {
    super(props);
    this.state = { tema: [] };
  }

  

  render() {
    const Price = style.div`
      font-weight: 400;

      h1,
      h2 {
        margin: 5px 0;
        color: ${this.props.tema.preco_cor};
      }

      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      span {
        font-size: 0.8rem;
      }

      small {
        display: block;
        font-size: 0.6rem;
      }
      p {
        display: block;
        font-size: 0.7rem;
      }
    `;

    const Space = style.small`
      height: 13px;
    `;
    const installment = getInstallments(this.props.children);
    return this.props.tema.formato != "1" ? (
      <Price>
        <span>à vista {this.props.pct}% de desconto</span>
        <h1>
          {formatMoney(
            this.props.children - (this.props.children * this.props.pct) / 100
          )}
        </h1>
        <small>À prazo</small>
        <h2>{formatMoney(this.props.children)}</h2>
        {this.props.parcelamento == 0 ? (
          <>
            {installment.available && (
              <small>
                em até {installment.quantity}x de{" "}
                {formatMoney(installment.value)} sem juros nos cartões
              </small>
            )}
            {!installment.available && <Space />}
          </>
        ) : (
          <small>
            {" "}
            em até {this.props.parcelamento}x de{" "}
            {formatMoney(this.props.children / this.props.parcelamento)} sem
            juros nos cartões
          </small>
        )}
      </Price>
    ) : (
      <Price>
        <span>À prazo</span>
        <h1>{formatMoney(this.props.children)}</h1>
        {this.props.parcelamento == 0 ? (
          <>
            {installment.available && (
              <small>
                em até {installment.quantity}x de{" "}
                {formatMoney(installment.value)} sem juros
              </small>
            )}
            {!installment.available && <Space />}
          </>
        ) : (
          <small>
            {" "}
            em até {this.props.parcelamento}x de{" "}
            {formatMoney(this.props.children / this.props.parcelamento)} sem
            juros nos cartões
          </small>
        )}
        <p>à vista {this.props.pct}% de desconto</p>
        <h2>
          {formatMoney(
            this.props.children - (this.props.children * this.props.pct) / 100
          )}
        </h2>
      </Price>
    );
  }
}

export default ProductPrice;
