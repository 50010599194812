import React, { Component } from 'react';
import style from 'styled-components';

const handleClick = e => {
  e.preventDefault();
  window.open(e.target.href, 'google-plus-share', 'width=490,height=530');
};

class GPlus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const Share = style.a`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      margin: 5px;
      vertical-align: middle;
      border-radius: 50%;
      background: ${this.props.cor};

      > svg {
        fill: #333;
      }

      &:hover {
        background: ${this.props.cordois};

        > svg {
          fill: #fff;
        }
      }
    `;
    return (
      <Share
        cor="#ffffff"
        title="Compartilhe no Google Plus"
        ariaLabel="Compartilhe no Google Plus"
        href={`https://plus.google.com/share?url=${this.props.url}`}
        onClick={handleClick}
      >
        <svg width={16} height={10}>
          <path d="M14 7V5h2V4h-2V2h-1v2h-2v1h2v2zM5 4v2h2.829A3.006 3.006 0 0 1 5 8C3.346 8 2 6.654 2 5s1.346-3 3-3c.717 0 1.407.257 1.943.724l1.314-1.508A4.955 4.955 0 0 0 5 0C2.243 0 0 2.243 0 5s2.243 5 5 5 5-2.243 5-5V4H5z" />
        </svg>
      </Share>
    );
  }
}

export default GPlus;
