import React from 'react';
import style from 'styled-components';

const Button = style.button`
  padding: 0;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  border: none;
  background: none;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

const Close = props => <Button {...props}>&times;</Button>;

export default Close;
