import style from 'styled-components';

const Col = style.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media(min-width: 800px) {
    flex: 1;
  }
`;

export default Col;
