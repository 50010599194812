import style, { css } from 'styled-components';

const Row = style.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-right: 0px;

  ${props =>
    props.vCenter &&
    css`
      align-items: center;
    `}
`;

export default Row;
