import React, { Component } from 'react';
import { formatMoney } from '../../tools';
import CardBranco from '../../images/cardWithe.png';
import style from 'styled-components';
import api2 from '../../api2';

class Parcelamento extends Component {
  constructor(props) {
    super(props);
    this.state = { tema: [], cartoes: [] };
  }

  componentDidMount() {
    api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
    api2.get('/home/cartoes').then(res => {
      this.setState({
        cartoes: res.data.response
      });
    });
  }

  render() {
    const Parce = style.div`
      background: ${this.state.tema.preco_cor};
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 5px;
      width: 200px;
      text-align: left;
      position: relative;
      right: 0px;
      display: inline-block;
      color: rgb(238, 238, 238);
    `;
    const Image = style.img`
      width: 20px;
      margin-top: 5px;
      display: inline-block;
      position: relative;
      top: 3px;
      fill: rgb(238, 238, 238);
      `;
    const H4a = style.h4`
      margin-left: 5px;
      margin-top: -5px;
      display: inline-block;
    `;
    const AreaParce = style.div`
      width: 190px;
      margin: auto;
    `;

    return (
      <div>
        {this.props.parcelamento == 0 && this.props.valor < 50 ? (
          ''
        ) : (
          <Parce>
            <div style={{ textAlign: 'center' }}>
              <Image src={CardBranco} alt="Cartão" width="20px" />
              <H4a>PARCELAMENTO</H4a>
              <div style={{ width: '180px', margin: '-10px auto auto' }}>
                {this.state.cartoes != ''
                  ? this.state.cartoes.map(cartao => (
                      <img
                        style={{ width: '40px', margin: '2.5px' }}
                        title={cartao.nome}
                        src={cartao.url}
                        alt={cartao.nome}
                      />
                    ))
                  : ''}
              </div>
            </div>
            <AreaParce>
              <div>
                {this.props.parcelamento == 0 ? (
                  this.props.valor > 100 ? (
                    <table
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        fontSize: '10px'
                      }}
                    >
                      <tr>
                        <td>1x</td>
                        <td>
                          <b>{formatMoney(this.props.valor)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>2x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>3x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>4x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>5x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>6x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <table
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        fontSize: '10px'
                      }}
                    >
                      <tr>
                        <td>1x</td>
                        <td>
                          <b>{formatMoney(this.props.valor)} </b> sem juros
                        </td>
                      </tr>
                      <tr>
                        <td>2x</td>
                        <td>
                          <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                        </td>
                      </tr>
                    </table>
                  )
                ) : (
                  ''
                )}
                {this.props.parcelamento == 1 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 2 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 3 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 4 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 5 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 6 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 7 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 8 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>8x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 8)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 9 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>8x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 8)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>9x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 9)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 10 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>8x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 8)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>9x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 9)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>10x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 10)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 11 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>8x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 8)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>9x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 9)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>10x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 10)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>11x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 11)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
                {this.props.parcelamento == 12 ? (
                  <table
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      fontSize: '10px'
                    }}
                  >
                    <tr>
                      <td>1x</td>
                      <td>
                        <b>{formatMoney(this.props.valor)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>2x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 2)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>3x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 3)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>4x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 4)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>5x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 5)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>6x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 6)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>7x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 7)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>8x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 8)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>9x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 9)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>10x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 10)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>11x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 11)} </b> sem juros
                      </td>
                    </tr>
                    <tr>
                      <td>12x</td>
                      <td>
                        <b>{formatMoney(this.props.valor / 12)} </b> sem juros
                      </td>
                    </tr>
                  </table>
                ) : (
                  ''
                )}
              </div>
            </AreaParce>
          </Parce>
        )}
      </div>
    );
  }
}

export default Parcelamento;
