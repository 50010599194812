import React, { Component } from 'react';
import style from 'styled-components';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap } from 'react-google-maps';
import { GoogleMap, Marker } from 'react-google-maps';
import api2 from '../../api2';

class Mapeixon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapa: { lat: -7.987341, lng: -38.297307 }
    };
  }

  componentDidMount() {
    api2.get('/home/mapa').then(res => {
      this.setState({
        mapa: res.data.response
      });
    });
  }

  render() {
    return (
      <GoogleMap defaultZoom={20} defaultCenter={this.state.mapa}>
        <Marker position={this.state.mapa} />
      </GoogleMap>
    );
  }
}

const Mapinha = style.div`
  height: 450px;
  margin-bottom: 35px;
  margin-top: 35px;
`;
const Mapa = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBA6CwrTEhAZQNmzQzraQ8g7k1rMUzRQ3I',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <Mapinha />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <>
    <div>
      <Mapeixon />
    </div>
  </>
));

export default Mapa;
