import React, { Component } from 'react';
import style from 'styled-components';

class Whatsapp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const Share = style.a`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      margin: 5px;
      vertical-align: middle;
      border-radius: 50%;
      background: ${this.props.cor};

      > svg {
        fill: #333;
      }

      &:hover {
        background: ${this.props.cordois};

        > svg {
          fill: #fff;
        }
      }
    `;
    return (
      <Share
        cor="#ffffff"
        href={`https://wa.me/?text=${encodeURI(
          [this.props.text, ', veja em: ', this.props.url].join('')
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg width={16} height={16}>
          <path d="M16 7.794c0 4.304-3.516 7.794-7.855 7.794a7.868 7.868 0 0 1-3.796-.97L0 16l1.418-4.182A7.712 7.712 0 0 1 .29 7.794C.29 3.489 3.807 0 8.145 0 12.485 0 16 3.49 16 7.794zM8.145 1.241c-3.641 0-6.603 2.94-6.603 6.553 0 1.434.467 2.762 1.258 3.842l-.825 2.433 2.537-.806a6.6 6.6 0 0 0 3.634 1.084c3.64 0 6.603-2.94 6.603-6.553s-2.962-6.553-6.604-6.553zm3.967 8.348c-.049-.08-.177-.127-.37-.223-.192-.095-1.139-.558-1.315-.621-.176-.064-.305-.096-.433.095s-.497.622-.61.749c-.112.128-.225.144-.417.048-.193-.095-.813-.297-1.548-.948a5.76 5.76 0 0 1-1.071-1.323c-.113-.19-.012-.294.084-.39.087-.085.193-.222.289-.334.096-.112.128-.191.192-.319.065-.127.033-.239-.016-.334-.048-.096-.433-1.036-.594-1.419-.16-.382-.32-.318-.433-.318-.112 0-.24-.016-.369-.016a.71.71 0 0 0-.513.239c-.177.191-.674.653-.674 1.593s.69 1.849.786 1.976c.096.127 1.332 2.119 3.29 2.884 1.957.765 1.957.51 2.31.478.352-.032 1.139-.463 1.3-.908.16-.447.16-.83.112-.909z" />
        </svg>
      </Share>
    );
  }
}

export default Whatsapp;
