import React, { Component } from "react";
import { Link } from "react-router-dom";
import style from "styled-components";
import ProductName from "../product-name";
import Cod from "../../components/cod";
import ProductPrice from "../product-price";

class Product extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const Button = style.button`
      padding: 0.8rem 1.5rem;
      font-size: 0.8rem;
      border: none;
      color: #333;
      background: ${this.props.tema.btn_primario};
      border-radius: 100px;

      &:hover {
        color: #fff;
        background: ${this.props.tema.btn_secundario};
      }
    `;
    const ProductImageWrapper = style.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      margin: auto;
    `;

    const ProductLink = style(Link)`
      display: inline-block;
      width: 275px;
      padding: 15px;
      margin: 10px;
      position: relative;
      text-align: center;
      border: #eee 1px solid;
      border-radius: 4px;

      &:hover {
        background: #f5f5f5;
      }
    `;

    const Unavailable = style.div`
      padding: 5px 15px;
      margin: auto;
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      background: #b61616;
      border-radius: 100px;
    `;
    return (
      <ProductLink to={`/produto/${this.props.codigo}`}>
        <Cod cod={this.props.codigo} />
        <ProductImageWrapper>
          <img src={this.props.imagem} alt="Imagem do produto" />
        </ProductImageWrapper>
        <ProductName small style={{ height: 75 }}>
          {this.props.nome}
        </ProductName>
        {this.props.available !== "1" ? (
          <Unavailable>Indisponível</Unavailable>
        ) : (
          ""
        )}
        <ProductPrice
          pct={this.props.pct == "" ? "5" : this.props.pct}
          parcelamento={this.props.parcelamento}
          tema={this.props.tema}
        >
          {this.props.valor}
        </ProductPrice>
        <br />
        <Button>+ Detalhes</Button>
      </ProductLink>
    );
  }
}

export default Product;
