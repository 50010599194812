import React, { Component } from 'react';
import style from 'styled-components';
import MetaTags from 'react-meta-tags';
import Slider from 'react-slick';
import axios from 'axios';
import Loading from '../../components/loading';
import { Container } from '../../components/grid';
import Banner from '../../components/banner';
import Legend from '../../components/legend';
import Product from '../../components/product';
import Btnprecompra from '../../components/btn-precompra';
import api from '../../api';
import api2 from '../../api2';

const ItemsSection = style.section`
  padding-top: 170px;
  margin-top: -150px;
  text-align: center;
`;

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      banners: [],
      produtos: [],
      vendido: [],
      novidade: [],
      oferta: [],
      marcas: [],
      tema: []
    };
  }

  componentDidMount = async () => {

    await api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response
      });
    });


    const getProducts = () => api.get(`/produtos/`);
    const getBanners = () => api.get(`/banners/`);

    api.get('/vendido').then(res => {
      this.setState({
        vendido: res.data
      });
    });
    api.get('/oferta').then(res => {
      this.setState({
        oferta: res.data
      });
    });
    api.get('/novidade').then(res => {
      this.setState({
        novidade: res.data
      });
    });
    api.get('/marcas').then(res => {
      this.setState({
        marcas: res.data
      });
    });

    axios
      .all([getProducts, getBanners])
      .then(
        axios.spread((products, banners) => {
          products().then(res => {
            if (res) {
              const { data } = res;
              this.setState({ produtos: data, loaded: true });
            }
          });

          banners().then(res => {
            if (res) {
              const { data } = res;
              this.setState({ banners: data });
            }
          });
        })
      )
      .catch(e => {
        console.error(e);
        alert('Falha ao carregar as informações!');
      })
      .finally(() => this.setState({}));
  }

  render() {
    const settings = {
      infinite: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const marcas = {
      speed: 500,
      infinite: true,
      autoplay: true,
      pauseOnHover: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };

    return (
      <>
        <MetaTags>
          <title>Inove Info</title>
          <meta name="description" content="Inove Info" />
          <meta property="og:title" content="Inove Info" />
          <meta property="og:image" content="" />
        </MetaTags>
        <Banner images={this.state.banners} />
        {this.state.loaded ? (
          <Container>
            <>
              {this.state.vendido != '' ? (
                <ItemsSection key="1">
                  <Legend nome="Mais Vendidos"></Legend>
                  <Slider {...settings}>
                    {this.state.vendido.map(pdt => (
                      <Product key={pdt.id} tema={this.state.tema} {...pdt} />
                    ))}
                  </Slider>
                </ItemsSection>
              ) : (
                ''
              )}
            </>
            <>
              {this.state.oferta != '' ? (
                <ItemsSection key="1">
                  <Legend nome="Ofertas"></Legend>
                  <Slider {...settings}>
                    {this.state.oferta.map(pdt => (
                      <Product key={pdt.id} tema={this.state.tema} {...pdt} />
                    ))}
                  </Slider>
                </ItemsSection>
              ) : (
                ''
              )}
            </>
            <>
              {this.state.novidade != '' ? (
                <ItemsSection key="1">
                  <Legend nome="Novidades"></Legend>
                  <Slider {...settings}>
                    {this.state.novidade.map(pdt => (
                      <Product key={pdt.id} tema={this.state.tema} {...pdt} />
                    ))}
                  </Slider>
                </ItemsSection>
              ) : (
                ''
              )}
            </>
            {this.state.marcas != '' ? (
              <div
                style={{
                  background: '#f5f5f5',
                  borderRadius: '15px',
                  marginTop: '40px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  textAlign: 'center'
                }}
              >
                <Slider {...marcas}>
                  {this.state.marcas.map(marca => (
                    <div
                      style={{
                        height: '70px',
                        width: '172px',
                        marginLeft: '50px',
                        marginRight: '50px',
                        textAlign: 'center'
                      }}
                      key={marca.id}
                    >
                      <a
                        href={`/marcas/?marca=${marca.id}`}
                        style={{ textAlign: 'center' }}
                        target="blank"
                      >
                        <img
                          style={{ width: '70px', margin: 'auto' }}
                          src={marca.link}
                          alt={marca.nome}
                          title={marca.nome}
                        />
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              ''
            )}
            <>
              {this.state.produtos.map(category => (
                <ItemsSection key={category.id} id={`category-${category.id}`}>
                  <Legend nome={category.nome_categoria}></Legend>
                  <Slider {...settings}>
                    {category.produtos.map(pdt => (
                      <Product key={pdt.id} tema={this.state.tema} {...pdt} />
                    ))}
                  </Slider>
                </ItemsSection>
              ))}
            </>
            <Btnprecompra tema={this.state.tema} />
          </Container>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

export default HomeScreen;
