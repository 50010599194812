import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api2 from '../../api2';

class LogoInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: []
    };
  }

  componentDidMount() {
    api2.get('/home/logotopo').then(res => {
      this.setState({
        logo: res.data.response
      });
    });
  }

  render() {
    return (
      <Link to="/">
        <img width={100} src={this.state.logo.url} alt="logo" />
      </Link>
    );
  }
}

export default LogoInfo;
