import React, { Component } from 'react';
import style from 'styled-components';

const handleClick = e => {
  e.preventDefault();
  window.open(e.target.href, 'twitter-share', 'width=550,height=235');
};

class Twitter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const Share = style.a`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      margin: 5px;
      vertical-align: middle;
      border-radius: 50%;
      background: ${this.props.cor};

      > svg {
        fill: #333;
      }

      &:hover {
        background: ${this.props.cordois};

        > svg {
          fill: #fff;
        }
      }
    `;
    return (
      <Share
        cor="#ffffff"
        title="Compartilhe no Twitter"
        ariaLabel="Compartilhe no Twitter"
        href={`https://twitter.com/intent/tweet?text=${
          this.props.text
        }&amp;url=${this.props.url}`}
        onClick={handleClick}
      >
        <svg width={16} height={12.997}>
          <path d="M16 1.538c-.589.261-1.22.438-1.885.517A3.296 3.296 0 0 0 15.558.24a6.617 6.617 0 0 1-2.086.797A3.282 3.282 0 0 0 7.88 4.03 9.318 9.318 0 0 1 1.115.6 3.257 3.257 0 0 0 .67 2.25a3.28 3.28 0 0 0 1.46 2.731A3.283 3.283 0 0 1 .644 4.57v.04A3.286 3.286 0 0 0 3.276 7.83a3.325 3.325 0 0 1-.864.115 3.15 3.15 0 0 1-.618-.061 3.284 3.284 0 0 0 3.065 2.28 6.585 6.585 0 0 1-4.076 1.402c-.265 0-.526-.016-.783-.045a9.278 9.278 0 0 0 5.031 1.477c6.038 0 9.338-5.001 9.338-9.338l-.01-.425A6.554 6.554 0 0 0 16 1.538z" />
        </svg>
      </Share>
    );
  }
}

export default Twitter;
