import React, { Component } from 'react';
import style from 'styled-components';
import Img from '../../images/carrinho.png';
import api2 from '../../api2';

class Btnprecompra extends Component {
  constructor(props) {
    super(props);
    this.state = { meio: [] };
  }

  componentDidMount() {
    api2.get('/home/meio').then(res => {
      this.setState({
        meio: res.data.response
      });
    });
  }

  render() {
    const Circle = style.div`
      position: fixed;
      right: 10px;
      bottom: 10px;
      z-index: 99999;
    `;
    const CircleInt = style.div`
      background: ${this.props.tema.btn_primario}
      border: none;
      border-radius: 100%;
      z-index: 999999;
      padding-left: 1px;
      padding-right: 1px;

      img{
        z-index: 99999;
        width: 50px;
        height: 50px;
        padding: 10px;
        margin-bottom: -2px;
      }
    `;
    return (
      <Circle>
        <a href="/precompra" target="blank">
          <CircleInt>
            <img src={Img} alt="Pré-compra" />
          </CircleInt>
        </a>
      </Circle>
    );
  }
}

export default Btnprecompra;
