import style from 'styled-components';

export const ElementWrapper = style.div`
  margin: 15px auto;
  position: relative;
`;

export const Label = style.label`
  display: block;
  margin-bottom: 5px;
`;

export const ElementBase = `
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f6f6f6;
  background-clip: padding-box;
  border: 1px solid #dfdfdf;
  border-radius: .15rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus {
    outline: 0;
    border-color: #11aeac;
    box-shadow: 0 0 0 .2rem #11aeac4d;
  }
`;
