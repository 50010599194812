import React, { Component } from 'react';
import style from 'styled-components';

const handleClick = e => {
  e.preventDefault();
  window.open(e.target.href, 'facebook-share', 'width=580,height=296');
};

class Facebook extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const Share = style.a`
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      margin: 5px;
      vertical-align: middle;
      border-radius: 50%;
      background: ${this.props.cor};

      > svg {
        fill: #333;
      }

      &:hover {
        background: ${this.props.cordois};

        > svg {
          fill: #fff;
        }
      }
    `;
    return (
      <Share
        cor="#ffffff"
        title="Compartilhe no Facebook"
        ariaLabel="Compartilhe no Facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${this.props.url}`}
        onClick={handleClick}
      >
        <svg width={8.65} height={16}>
          <path d="M8.325.003L6.25 0C3.919 0 2.412 1.546 2.412 3.938v1.815H.326A.326.326 0 0 0 0 6.08v2.63c0 .18.146.326.326.326h2.086v6.638c0 .18.146.326.327.326H5.46c.18 0 .326-.146.326-.326V9.036h2.439c.18 0 .326-.146.326-.326l.001-2.63a.327.327 0 0 0-.326-.327h-2.44V4.214c0-.74.176-1.115 1.14-1.115h1.397c.18 0 .326-.147.326-.327V.33a.326.326 0 0 0-.325-.327z" />
        </svg>
      </Share>
    );
  }
}

export default Facebook;
