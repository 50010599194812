import React, { Component } from "react";
import style from "styled-components";
import renderHTML from "react-render-html";
import ReactModal from "react-modal";
import MetaTags from "react-meta-tags";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container, Row, Col } from "../../components/grid";
import Carousel from "../../components/carousel";
import ProductName from "../../components/product-name";
import ProductPrice from "../../components/product-price";
import Legend from "../../components/legend";
import ProducDescription from "../../components/product-description";
import { Facebook, Twitter, GPlus, Whatsapp } from "../../components/share";
import { Input, Textarea } from "../../components/form";
import Close from "../../components/close";
import Loading from "../../components/loading";
import Parcelamento from "../../components/parcelamento";
import ImgWppPdt from "../../images/whatsapp-business-icon.1a922b19.png";
import api from "../../api";
import Btnprecompra from "../../components/btn-precompra";
import UrlPadrao from "../../url";
import api2 from "../../api2";

const SmallImg = style.small`
  font-size: 10px;
  position: relative;
  top: -5px;
`;
const SmallCate = style.small`
  font-size: 12px;
  position: relative;
  top: -5px;
`;

const InfoProduct = style.div`
  @media (max-width: 799px) {
    text-align: center;
  }

  @media (min-width: 800px) {
    text-align: right;
  }
`;

const Unavailable = style.div`
  display: inline-block;
  margin: 15px 0;
  padding: 5px 15px;
  color: #fff;
  background: #b61616;
  border-radius: 100px;
`;

class ProductScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      showModal: false,
      showModalWpp: false,
      loading: false,
      id: null,
      codigo: "",
      nome: "",
      valor: "",
      parcelamento: "",
      descricao: "",
      especificacao: "",
      name: "",
      email: "",
      phone: "",
      message: "",
      categoria: "",
      cate_num: "",
      data_inicial: "",
      data_final: "",
      sub: "",
      pct: "",
      subcategoria: "",
      galeria: [],
      tema: [],
      whatsapp: [],
      visibleWpp: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleOpenModalWpp = this.handleOpenModalWpp.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalWpp = this.handleCloseModalWpp.bind(this);
  }

  componentDidMount() {
    this.getData();
    api2.get("/home/meio").then(res => {
      this.setState({
        tema: res.data.response,
      });
    });
    api2.get("/home/itenstopo").then(res => {
      this.setState({
        visibleWpp: res.data.response[2],
      });
    });
    api2.get("/home/whatsproduto").then(res => {
      this.setState({
        whatsapp: res.data.response,
      });
    });
    this.getData();
  }

  componentDidUpdate() {}

  getData() {
    const id = parseInt(this.props.match.params.id);
    if (parseInt(this.state.id) !== id) {
      api
        .get(`/produtos/${id}`)
        .then(res => {
          if (res) {
            this.setState({ ...res.data, loaded: true });
          }
        })
        .catch(e => console.error(e));
    }
  }

  handleChange({ target }) {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { id, name, email, phone, message } = this.state;

    if (name && email && phone && message) {
      this.setState({ loading: true });

      const params = new URLSearchParams();
      params.append("product", id);
      params.append("nome", name);
      params.append("email", email);
      params.append("telefone", phone);
      params.append("mensagem", message);

      api2
        .post("/home/email", params)
        .then(({ data }) => {
          if (data.sucess) {
            alert("Mensagem enviada com sucesso!");
            this.setState({
              showModal: false,
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else {
            alert(data.error);
          }
        })
        .catch(e => console.error(e))
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleOpenModalWpp() {
    this.setState({ showModalWpp: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseModalWpp() {
    this.setState({ showModalWpp: false });
  }

  render() {
    const urlProduct = `${UrlPadrao}/produto/${this.state.codigo}`;

    const LinkWpp = style.a`
      border: none;
      border-radius: 50%;
      background: ${this.state.tema.btn_primario};
      width: 40px;
      height: 40px;
      margin-right: 7px;
      margin-top: 2px;
      justifyContent: center;
      alignItems: center;

      img{
        margin: 7.5px;
      }
      &:hover {
        color: #fff;
        background: ${this.state.tema.btn_secundario};
      }

    `;
    const LinkWhats = style.a`
      border: none;
      border-radius: 25px;
      background: ${this.state.tema.btn_primario};
      width: 150px;
      height: 70px;
      margin-right: 7px;
      margin-top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      
      &:hover {
        color: #fff;
        background: ${this.state.tema.btn_secundario};
      }

    `;
    const Button = style.button`
      padding: 0.8rem 1.5rem;
      font-size: 0.8rem;
      border: none;
      color: #333;
      background: ${this.state.tema.btn_primario};
      border-radius: 100px;

      &:hover {
        color: #fff;
        background: ${this.state.tema.btn_secundario};
      }
    `;

    var dataInicial = new Date(Date.parse(`${this.state.data_inicial}`));
    var dayI = dataInicial.getUTCDate();
    var month = dataInicial.getUTCMonth();
    var datainitial =
      dayI + "/" + (month + 1) + "/" + dataInicial.getFullYear();
    var dataFinal = new Date(Date.parse(`${this.state.data_final}`));
    var day = dataFinal.getUTCDate();
    var monthF = dataInicial.getUTCMonth();
    var datalast = day + "/" + (monthF + 1) + "/" + dataFinal.getFullYear();
    return this.state.loaded ? (
      <div style={{ paddingTop: 170 }}>
        <MetaTags>
          <title>{`Inove Info - ${this.state.nome}`}</title>
          <meta
            name="description"
            content={`Na Inove Info você encontra ${this.state.nome}`}
          />
          <meta property="og:title" content={this.state.nome} />
          <meta property="og:image" content={this.state.imagem} />
        </MetaTags>
        <Container>
          <Row>
            <Col>
              <small style={{ fontSize: "13px" }}>
                <label>
                  <a
                    target="blank"
                    href={`/categoria/?categoria=${this.state.cate_num}`}
                  >
                    {this.state.categoria}
                  </a>
                  {this.state.sub != "" ? (
                    <>
                      {" > "}
                      <a
                        href={`/subcategoria/?subcategoria=${
                          this.state.subcategoria
                        }`}
                      >
                        {this.state.sub}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                  {" > "}
                  {this.state.nome}
                  <div id="bobadorato" />
                </label>
              </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <ProductName code={this.state.codigo}>
                {this.state.nome}
              </ProductName>
              <SmallImg>Imagens meramente ilustrativas</SmallImg>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.galeria && <Carousel images={this.state.galeria} />}
            </Col>
            <Col>
              <InfoProduct>
                <ProductPrice
                  pct={this.state.pct == "" ? "5" : this.state.pct}
                  tema={this.state.tema}
                  parcelamento={this.state.parcelamento}
                >
                  {this.state.valor}
                </ProductPrice>
                {this.state.available !== "1" ? (
                  <Unavailable>Indisponível</Unavailable>
                ) : (
                  ""
                )}
                <br />
                {this.state.data_inicial != "" ? (
                  <small style={{ fontSize: "0.7rem" }}>
                    oferta valida de {datainitial} até {datalast}
                  </small>
                ) : (
                  ""
                )}
                <br />
                <br />
                <Parcelamento
                  parcelamento={this.state.parcelamento}
                  valor={this.state.valor}
                />
                <br />
                <div>
                  <Facebook
                    cor={this.state.tema.btn_primario}
                    cordois={this.state.tema.btn_secundario}
                    url={urlProduct}
                  />
                  <GPlus
                    cor={this.state.tema.btn_primario}
                    cordois={this.state.tema.btn_secundario}
                    url={urlProduct}
                  />
                  <Twitter
                    cor={this.state.tema.btn_primario}
                    cordois={this.state.tema.btn_secundario}
                    text={this.state.nome}
                    url={urlProduct}
                  />
                  <Whatsapp
                    cor={this.state.tema.btn_primario}
                    cordois={this.state.tema.btn_secundario}
                    text={this.state.nome}
                    url={urlProduct}
                  />
                </div>
                <br />
                <div style={{ display: "inline-flex" }}>
                  {this.state.visibleWpp.exibir == 1 ? (
                    <LinkWpp onClick={this.handleOpenModalWpp}>
                      <img
                        src={ImgWppPdt}
                        style={{ width: "25px" }}
                        alt="wpp"
                      />
                    </LinkWpp>
                  ) : (
                    ""
                  )}
                  <Button onClick={this.handleOpenModal}>
                    Contato/Reserva
                  </Button>
                </div>
              </InfoProduct>
            </Col>
          </Row>
          <br />
          <Legend nome="Detalhes" />
          <br />
          {this.state.descricao && (
            <Tabs>
              <TabList>
                <Tab>Descrição</Tab>
                {this.state.especificacao != "" ? (
                  <Tab>Especificações</Tab>
                ) : (
                  <Tab disabled>Especificações</Tab>
                )}
              </TabList>

              <TabPanel>
                <ProducDescription>
                  {renderHTML(this.state.descricao)}
                </ProducDescription>
              </TabPanel>
              <TabPanel>
                <ProducDescription>
                  {this.state.especificacao != ""
                    ? renderHTML(this.state.especificacao)
                    : ""}
                </ProducDescription>
              </TabPanel>
            </Tabs>
          )}
          <Btnprecompra tema={this.state.tema} />
        </Container>
        <ReactModal isOpen={this.state.showModal} contentLabel="Modal Contato">
          <div style={{ textAlign: "right" }}>
            <Close onClick={this.handleCloseModal} />
          </div>
          <form onSubmit={this.handleSubmit}>
            <Input
              name="name"
              text="Nome"
              type="text"
              value={this.state.name}
              onChange={this.handleChange}
            />
            <Input
              name="email"
              text="E-mail"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <Input
              name="phone"
              text="Telefone"
              type="text"
              value={this.state.phone}
              onChange={this.handleChange}
            />
            <Textarea
              name="message"
              text="Mensagem"
              rows="10"
              value={this.state.message}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              <Button>Enviar</Button>
            </div>
          </form>
          {this.state.loading && <Loading />}
        </ReactModal>
        <ReactModal
          isOpen={this.state.showModalWpp}
          contentLabel="Modal Whatsapp"
        >
          <div style={{ textAlign: "right" }}>
            <Close onClick={this.handleCloseModalWpp} />
          </div>
       
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                  }}
                >
                {this.state.whatsapp.map(whats => (
                  <LinkWhats
                    href={`${whats.link} ${
                      this.state.nome
                    } que se encontra no link: ${urlProduct}`}
                    target="blank"
                  >
                    {" "}
                    {whats.nome}{" "}
                  </LinkWhats>
                  ))}
                </div>
          {this.state.loading && <Loading />}
        </ReactModal>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default ProductScreen;
