import React from 'react';
import style from 'styled-components';
import Slider from 'react-slick';

const BannerWrapper = style.div`
  margin: 110px -40px 0;
  margin-bottom: -40px !important;

  @media (max-width: 1080px) {
    margin-top: 150px;
  }
`;

const BannerImage = style.img`
  width: 100%;
  max-height: none;
`;

const settings = {
  arrows: false,
  speed: 500,
  autoplay: true
};

const Banner = props => (
  <BannerWrapper>
    <Slider {...settings}>
      {props.images.map(image => (
        <div key={image.id}>
          <a href={image.link} target="blank">
            <BannerImage src={image.url} alt="Banner" />
          </a>
        </div>
      ))}
    </Slider>
  </BannerWrapper>
);

export default Banner;
