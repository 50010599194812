import React from 'react';
import style from 'styled-components';
import Boleto from '../../images/payment-forms/boleto.svg';
import DinersClub from '../../images/payment-forms/diners-club.svg';
import Elo from '../../images/payment-forms/elo.svg';
import Hiper from '../../images/payment-forms/hiper.svg';
import Hipercard from '../../images/payment-forms/hipercard.svg';
import Mastercard from '../../images/payment-forms/mastercard.svg';
import MastercardMaestro from '../../images/payment-forms/mastercard-maestro.svg';
import Visa from '../../images/payment-forms/visa.svg';
import VisaElectron from '../../images/payment-forms/visa-electron.svg';

const payments = {
  boleto: {
    name: 'Boleto',
    image: Boleto
  },

  'diners-club': {
    name: 'Diners Club',
    image: DinersClub
  },

  elo: {
    name: 'Elo',
    image: Elo
  },

  hiper: {
    name: 'Hiper',
    image: Hiper
  },

  hipercard: {
    name: 'Hipercard',
    image: Hipercard
  },

  mastercard: {
    name: 'Mastercard',
    image: Mastercard
  },

  'mastercard-maestro': {
    name: 'Mastercard Maestro',
    image: MastercardMaestro
  },

  visa: {
    name: 'Visa',
    image: Visa
  },

  'visa-electron': {
    name: 'Visa Electron',
    image: VisaElectron
  }
};

const Image = style.img`
  margin: 5px;
`;

const PaymentForm = props => {
  const payment = payments[props.name];
  return <Image src={payment.image} alt={payment.name} />;
};

export default PaymentForm;
