import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomeScreen from './screens/home';
import ProductScreen from './screens/product';
import FilterScreen from './screens/filter';
import MarcasScreen from './screens/marcas';
import CategoriaScreen from './screens/categoria';
import SubcategoriaScreen from './screens/subcategoria';
import PrecompraScreen from './screens/precompra';
import CarrinhoScreen from './screens/carrinho';
import PromocaoScreen from './screens/promocao';
import EsportsScreen from './screens/esports';
import withTracker from './with-tracker';

const Router = () => (
  <Switch>
    <Route exact path="/" component={withTracker(HomeScreen)} />
    <Route path="/produto/:id" component={withTracker(ProductScreen)} />
    <Route path="/filter" component={withTracker(FilterScreen)} />
    <Route path="/marcas" component={withTracker(MarcasScreen)} />
    <Route path="/categoria" component={withTracker(CategoriaScreen)} />
    <Route path="/subcategoria" component={withTracker(SubcategoriaScreen)} />
    <Route path="/precompra" component={withTracker(PrecompraScreen)} />
    <Route path="/promocao" component={withTracker(PromocaoScreen)} />
    <Route path="/esports" component={withTracker(EsportsScreen)} />
    <Route path="/carrinho" component={withTracker(CarrinhoScreen)} />
  </Switch>
);

export default Router;
