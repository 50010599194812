import React from 'react';
import style, { css } from 'styled-components';
import SocialIcons from '../../images/social.png';

const Link = style.a`
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  background: url(${SocialIcons}) center no-repeat;
  transition: transform 200ms;
  border-radius: 50%;

  &:hover {
    transform: translate(0, -5px);
  }

  > span {
    display: none;
  }

  ${props =>
    props.social === 'facebook' &&
    css`
      background-position: 0 0;
    `}

  ${props =>
    props.social === 'instagram' &&
    css`
      background-position: -30px 0;
    `}

  ${props =>
    props.social === 'twitter' &&
    css`
      background-position: -60px 0;
    `}

  ${props =>
    props.social === 'youtube' &&
    css`
      background-position: -90px 0;
    `}

  ${props =>
    props.social === 'linkedin' &&
    css`
      background-position: -120px 0;
    `}

  ${props =>
    props.social === 'medium' &&
    css`
      background-position: -150px 0;
    `}

  ${props =>
    props.social === 'behance' &&
    css`
      background-position: -180px 0;
    `}

  ${props =>
    props.social === 'spotify' &&
    css`
      background-position: -210px 0;
    `}
`;

const info = {
  facebook: {
    url: 'https://facebook.com/',
    title: 'Facebook'
  },

  instagram: {
    url: 'https://instagram.com/',
    title: 'Instagram'
  },

  twitter: {
    url: 'https://twitter.com/',
    title: 'Twitter'
  },

  youtube: {
    url: 'https://youtube.com/channel/',
    title: 'Youtube'
  },

  medium: {
    url: 'https://medium.com/',
    title: 'Medium'
  },

  spotify: {
    url: 'https://open.spotify.com/user/',
    title: 'Spotify'
  },

  linkedin: {
    url: 'https://linkedin.com/company/',
    title: 'LinkedIn'
  }
};

const Social = props => {
  const socialInfo = info[props.name];
  return (
    <Link
      href={`${socialInfo.url}${props.children}`}
      target="_blank"
      rel="noopener noreferrer"
      title={socialInfo.title}
      social={props.name}
    >
      <span>{socialInfo.title}</span>
    </Link>
  );
};

export default Social;
