import React, { Component } from 'react';
import Wpp1 from '../../images/whatsapp-business.07e8de28.png';
import Wpp2 from '../../images/whatsapp-business-icon.1a922b19.png';
import api from '../../api';

class Wpp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wpp: 0,
      whatsapp: []
    };
    this.mudar = this.mudar.bind(this);
  }

  mudar() {
    if (this.state.wpp === 0) {
      this.setState({ wpp: 1 });
    } else {
      this.setState({ wpp: 0 });
    }
  }
  componentDidMount() {
    api.get('/whatsapp').then(res => {
      this.setState({
        whatsapp: res.data[0]
      });
    });
  }

  render() {
    return (
      <div style={{ marginTop: '5px' }}>
        <a
          href={`${this.state.whatsapp.link}`}
          target="blank"
          onMouseOver={this.mudar}
          onMouseOut={this.mudar}
        >
          <img width="25px" src={this.state.wpp ? Wpp2 : Wpp1} alt="Wpp" />
        </a>
      </div>
    );
  }
}

export default Wpp;
