import React, { Component } from 'react';
import Loading from '../../components/loading';
import { Row, Col, Container } from '../../components/grid';
import Product from '../../components/product';
import api from '../../api';
import axios from 'axios';
import Btnprecompra from '../../components/btn-precompra';

import api2 from '../../api2';

class Categoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      produtos: [],
      pesquisa: '',
      tema: []
    };
  }

  componentDidMount() {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    var data = {};
    partes.forEach(function(parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });
    api.get(`/produtos/?categoria=${data.categoria}`).then(res => {
      this.setState({
        produtos: res.data
      });
    });
    api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response,
        loaded: true
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.loaded ? (
          <Container style={{ marginTop: '150px' }}>
            <Row>
              {this.state.produtos.map(produto => (
                <div>
                  <h1 style={{ fontWeight: 'normal' }}>
                    {produto.produtos[0].cate_nome}
                  </h1>
                  {produto.produtos.map(pdt => (
                    <Product key={pdt.id} tema={this.state.tema} {...pdt} />
                  ))}
                </div>
              ))}
            </Row>
            <Btnprecompra tema={this.state.tema} />
          </Container>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default Categoria;
