import React, { Component } from 'react';
import style from 'styled-components';
import ReactModal from 'react-modal';
import QRCode from 'qrcode.react';
import QrReader from 'react-qr-reader';
import { Container } from '../../components/grid';
import Close from '../../components/close';
import carrinho from '../../images/carrinho.png';
import ProductPrice from '../../components/product-price';
import Parcelas from '../../components/parcelas';
import { formatMoney } from '../../tools';
import './Carrinho.scss';
import api2 from '../../api2';
import UrlFiles from '../../url_arquivos';

const Cod = style.div`
  padding: 3px 10px;
  margin: auto;
  position: relative;
  color: rgb(44, 44, 44);
  background: rgb(233, 232, 232) none repeat scroll 0% 0%;
  border-radius: 100px;
  font-size: 10px;
  width: 70px;
  text-align: center;
  float: left;
    `;

const Inputado = style.input`
  padding: 10px;
  width: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
  text-align: center;
  font-size: 18px;

`;

class Carrinho extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      produtos: [],
      resultado: [],
      input: '',
      valor: '0',
      valorOld: '0',
      numCarrinho: '',
      validado: 0,
      tema: []
    };

    this.openModal = this.openModal.bind(this);
    this.requisicao = this.requisicao.bind(this);
    this.requisicaoBtnMais = this.requisicaoBtnMais.bind(this);
    this.valorInput = this.valorInput.bind(this);
    this.produtos = this.produtos.bind(this);
    this.produtosBtnMenos = this.produtosBtnMenos.bind(this);
    this.handleTest = this.handleTest.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.ValorMais = this.ValorMais.bind(this);
    this.ValorMenos = this.ValorMenos.bind(this);
    this.enviar = this.enviar.bind(this);
    this.requisicaoCarrinho = this.requisicaoCarrinho.bind(this);
  }

  enviar() {
    api2
      .get('/home/carrinho/', {
        params: {
          prod: this.state.produtos.toString(),
          valor: this.state.valor
        }
      })
      .then(res => {
        this.setState({ numCarrinho: res.data.response });
      });
    var elementA = document.querySelector('.areaCompleta');
    elementA.classList.add('removeArea');
    this.go();
  }
  go() {
    window.scroll({
      // 1
      top: document.querySelector('#top').offsetTop - 800, // 2
      left: 0,
      behavior: 'smooth' // 3
    });
  }

  valorInput(e) {
    this.setState({ input: e.target.value });
  }

  requisicao(resultado) {
    this.setState({ input: '' });
    api2.get(`/home/nomeprod/?nomeprod=${resultado}`).then(res => {
      if (res.data.response != '') {
        alert('Produto não encontrado');
      } else {
        if (res.data.produtos[0].valor != '') {
          this.setState({
            valor:
              parseInt(this.state.valor) + parseInt(res.data.produtos[0].valor)
          });
        }
        var produto = this.state.produtos;
        let result = produto.push(resultado);
        this.produtos(result);
        this.openModal();
      }
    });
  }
  ValorMais(resultado) {
    this.setState({ input: '' });
    api2.get(`/home/nomeprod/?nomeprod=${resultado}`).then(res => {
      this.setState({
        valor: parseInt(this.state.valor) + parseInt(res.data.produtos[0].valor)
      });
    });
  }
  ValorMenos(resultado) {
    this.setState({ input: '' });
    api2.get(`/home/nomeprod/?nomeprod=${resultado}`).then(res => {
      this.setState({
        valor: parseInt(this.state.valor) - parseInt(res.data.produtos[0].valor)
      });
      if (this.state.valor < 0) {
        this.setState({ valor: 0 });
      }
    });
  }
  requisicaoBtnMais(resultado) {
    this.ValorMais(resultado);
    this.state.produtos.push(resultado);
    console.log(this.state.produtos);
    this.produtos(this.state.produtos);
  }

  produtos(result) {
    if (this.state.produtos != '') {
      api2
        .get('/home/pedidos/', {
          params: {
            prod: this.state.produtos
          }
        })
        .then(res => {
          this.setState({ resultado: res.data.produtos });
        });
      console.log(this.state.produtos);
    } else {
      this.setState({ resultado: [], valor: '0' });
    }
  }

  handleScan(data) {
    if (data) {
      const regex = /https?:\/\/(store|loja).inovesistemas.info\/produto\/(\d+)/i;
      const matches = data.match(regex);

      if (matches && matches[2]) {
        let resultado = data.substring(data.indexOf('produto/') + 8);
        this.requisicao(resultado);
        this.go();
      } else {
        alert('Código inválido, por favor tente novamente!');
      }
    }
  }

  handleTest(resultado) {
    this.requisicao(resultado);
    this.go();
  }

  openModal() {
    if (this.state.modal == false) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
    }
  }

  contarQunt(valor) {
    var contar = 0;
    var y = 0;

    for (y = 0; y < this.state.produtos.length; y++) {
      if (this.state.produtos[y] == valor) {
        contar++;
      }
    }
    return contar;
  }

  produtosBtnMenos(valor) {
    var index = this.state.produtos.indexOf(valor);
    if (index > -1) {
      this.ValorMenos(valor);
      this.state.produtos.splice(index, 1);
      this.produtos(this.state.produtos);
    }
  }
  requisicaoCarrinho(valor) {
    api2.get(`/home/produtos/?numprod=${valor}`).then(res => {
      let string = res.data.response[0].itens;
      let produtos = string.split(',');
      this.setState({
        produtos: produtos,
        valor: res.data.response[0].valor
      });
      this.produtos(this.state.produtos);
    });
  }
  componentDidMount() {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    var data = {};
    partes.forEach(function(parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });
    var numCarrinho = data.numprod;
    this.setState({ numCarrinho: numCarrinho });
    if (data.numprod) {
      this.requisicaoCarrinho(numCarrinho);
    }
    api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }

  render() {
    const Butaozin = style.button`
      top: 244px;
      border: medium none;
      font-size: 17px;
      padding: 11px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: rgb(255, 200, 10);
      color: rgb(44, 44, 44);
      width: 40px;
      margin-left: -10px;
      z-index: 99999999;

    `;

    const CarrinhoArea = style.div`
      display: flex;
      max-width: 500px;
      margin: auto auto 6px;
      padding-bottom: 2px;
      border-bottom: 1px solid #000;
    `;
    const CarrinhoCentro = style.div`
        display: flex;
    `;
    const CarrinhoImg = style.div`
        max-width: 100px;
        max-height: 120px;
        margin-right: 25px;
        text-align: center;
    `;
    const CarrinhoTxt = style.div`
        width: 450px;

        @media (max-width: 500px) {
          width: 250px;
        }
    `;
    const CarrinhoPreco = style.div`
        width: 160px;
    `;
    const SmallPreco = style.small`
        float: left;
        position: relative;
        left: -100px;
        margin-top: 30px;
    `;

    const CarrinhoValor = style.div`
      max-width: 500px;
      margin: -60px auto auto;
      text-align: center;
      font-size: 22px;
    `;
    const ButaoFinal = style.button`
      margin-top: 25px;
      margin-bottom: 25px;
      border: medium none;
      padding: 10px;
      border-radius: 5px;
      background-color: rgb(255, 200, 10);
      color: rgb(44, 44, 44);
      width: 175px;
      z-index: 99999999;
    `;

    return (
      <Container style={{ marginTop: '150px' }} id="top">
        <div className="areaCompleta">
          <div
            style={{ maxWidth: '175px', margin: 'auto', textAlign: 'center' }}
          >
            <h2>Carrinho {this.state.numCarrinho}</h2>
            <br />
            <br />
          </div>
          {this.state.produtos == '' ? (
            <div style={{ textAlign: 'center' }}>
              <form>
                <Inputado
                  onChange={this.valorInput}
                  type="tel"
                  placeholder="Ex.: 143"
                  name="numprod"
                  value={this.state.input}
                />
                <br />
                <ButaoFinal>Carregar carrinho</ButaoFinal>
              </form>
            </div>
          ) : (
            ''
          )}
          <ReactModal isOpen={this.state.modal} contentLabel="QRCode Modal">
            <div style={{ textAlign: 'right' }}>
              <Close onClick={this.openModal} />
            </div>
            <div
              style={{
                maxWidth: '300px',
                margin: '15px auto',
                textAlign: 'center'
              }}
            >
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: '100%' }}
              />
              <p>
                <b>
                  Escaneie o Produto
                  <br />
                  ou
                  <br />
                  Digite o código
                </b>
              </p>
              <form action="#">
                <Butaozin onClick={() => this.handleTest(this.state.input)}>
                  <img src={carrinho} alt="carrinho" />
                </Butaozin>
              </form>
            </div>
          </ReactModal>
          <div>
            {this.state.resultado.map(produto => (
              <>
                <CarrinhoArea>
                  <CarrinhoCentro>
                    <CarrinhoImg>
                      <img
                        src={`${UrlFiles}/produtos/${produto.imagem}`}
                        alt="img prod"
                      />
                    </CarrinhoImg>
                    <br />
                    <CarrinhoTxt>
                      <Cod>Cód.: {produto.codigo}</Cod>
                      <br />
                      <a href={`/produto/${produto.codigo}`}>
                        <p style={{ maxWidth: '350px', marginTop: '5px' }}>
                          {produto.nome}
                        </p>
                      </a>
                      <br />
                      <CarrinhoPreco>
                        <SmallPreco>
                          {this.contarQunt(produto.codigo)}
                          {'x '}
                          {formatMoney(produto.valor)}
                        </SmallPreco>
                      </CarrinhoPreco>
                    </CarrinhoTxt>
                  </CarrinhoCentro>
                </CarrinhoArea>
              </>
            ))}
            <CarrinhoValor>
              <br />
              <br />
              {this.state.valor != '0' ? (
                <ProductPrice  pct='5' parcelamento='0' tema={this.state.tema}>
                  {this.state.valor}
                </ProductPrice>
              ) : (
                ''
              )}
              <br />
              {this.state.valor > 50 ? (
                <Parcelas valor={this.state.valor} />
              ) : (
                ''
              )}
            </CarrinhoValor>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {this.state.produtos != '' ? (
            <ButaoFinal
              onClick={() =>
                this.state.validado == 1
                  ? this.setState({ validado: 0 })
                  : this.setState({ validado: 1 })
              }
            >
              Ver QRCode
            </ButaoFinal>
          ) : (
            ''
          )}
          {this.state.validado == 1 ? (
            <div style={{ textAlign: 'center' }}>
              <h3>Código do pedido {this.state.numCarrinho}</h3>
              <div
                style={{
                  textAlign: 'center',
                  padding: '50px',
                  background: 'rgb(233, 232, 232) none repeat scroll 0% 0%',
                  borderRadius: '5px'
                }}
              >
                <a
                  href={`/carrinho/?numprod=${this.state.numCarrinho}`}
                  target="blank"
                >
                  <QRCode
                    bgColor="rgb(233, 232, 232)"
                    value={`https://loja.inovesistemas.info/carrinho/?numprod=${this.state.numCarrinho}`}
                  />
                </a>
              </div>
              <p style={{ marginTop: '20px' }}>
                Direcione-se até o caixa e apresente o QrCode
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
      </Container>
    );
  }
}

export default Carrinho;
