import React from 'react';
import style, { createGlobalStyle } from 'styled-components';
import Slider from 'react-slick';

const CarolselWrapper = style.div`
  max-width: 700px;
  padding-left: 15px;
  padding-right: 15px;
`;

const ImageWrapper = style.div`
  text-align: center;

  > img {
    display: inline-block;
    max-height: 400px;
  }
`;
const Video = style.iframe`
  width: 700px; 
  height: 400px;

  @media (max-width: 800px) {
    width: 600px; 
    height: 300px;
  }
  @media (max-width: 500px) {
    width: 250px;
    height: 150px
  }
`;

const ArrowsStyle = createGlobalStyle`
  .slick-thumb {
    position: relative;
    bottom: 0;
  }

  .slick-thumb li {
    width: 50px;
    height: 50px;
    margin: 0 15px;
  }

  .slick-thumb li img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
    filter: grayscale(100%);
  }

  .slick-thumb li.slick-active img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
    filter: grayscale(0);
  }
`;

const Carousel = props => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots slick-thumb',
    customPaging(i) {
      return <img src={props.images[i].url} alt="Thumb" />;
    }
  };

  return (
    <CarolselWrapper>
      <ArrowsStyle />
      <Slider {...settings}>
        {props.images.map(image => (
          <ImageWrapper key={image.id}>
          {image.video == 0 ? (
            <img src={image.url} alt="Imagem do produto" />
            ) : (
              <Video src={image.link} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></Video>
            ) }
          </ImageWrapper>
        ))}
      </Slider>
    </CarolselWrapper>
  );
};

export default Carousel;
