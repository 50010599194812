import React, { Component } from 'react';
import Loading from '../../components/loading';
import { Row, Col, Container } from '../../components/grid';
import Product from '../../components/product';
import api from '../../api';
import axios from 'axios';
import Btnprecompra from '../../components/btn-precompra';

import api2 from '../../api2';

class Marcas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      produtos: [],
      result: [],
      marcas: [],
      tema: []
    };
  }

  componentDidMount() {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    var data = {};
    partes.forEach(function(parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });
    api.get(`/produtos/?marca=${data.marca}`).then(res => {
      this.setState({
        result: res.data
      });
    });
    api.get(`/marcas/?marca=${data.marca}`).then(res => {
      this.setState({
        marcas: res.data,
        loaded: true
      });
    });
    console.log(data.marca);
    api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.loaded ? (
          <Container style={{ marginTop: '150px' }}>
            <h1 style={{ fontWeight: 'normal' }}>
              <b>{this.state.marcas[0].nome}</b>
            </h1>
            <Row>
              {this.state.result.map(produto =>
                produto.produtos.map(pdt => <Product key={pdt.id} tema={this.state.tema} {...pdt} />)
              )}
            </Row>
            <Btnprecompra tema={this.state.tema} />
          </Container>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default Marcas;
