import React, { Component } from 'react';
import { Container, Row, Col } from '../../components/grid';
import api from '../../api';
import api2 from '../../api2';
import Product from '../../components/product';
import Btnprecompra from '../../components/btn-precompra';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pesquisa: [],
      result: [],
      resultado: '',
      tema: []
    };

    this.resultado = this.resultado.bind(this);
  }

  resultado(p) {
    this.setState({ resultado: p });
    api.get(`/produtos/?pesquisa=${p}`).then(res => {
      this.setState({
        pesquisa: res.data[0].produtos,
        result: res.data
      });
    });
    api2.get('/home/meio').then(res => {
      this.setState({
        tema: res.data.response
      });
    });
  }
  componentDidMount() {
    var query = window.location.search.slice(1);
    var partes = query.split('&');
    var data = {};
    partes.forEach(function(parte) {
      var chaveValor = parte.split('=');
      var chave = chaveValor[0];
      var valor = chaveValor[1];
      data[chave] = valor;
    });
    var pesquisa = data.pesquisa;
    this.resultado(pesquisa);
  }

  render() {
    return (
      <Container>
        <Row>
          <h1 style={{ marginTop: '150px' }}>
            Resultado de:{' '}
            <span style={{ fontWeight: 'normal' }}>{this.state.resultado}</span>
          </h1>
        </Row>
        {this.state.pesquisa != '' ? (
          this.state.result.map(a => (
            <Row>
              {a.produtos.map(pdt => (
                <Product key={pdt.id} tema={this.state.tema} {...pdt} />
              ))}
            </Row>
          ))
        ) : (
          <h3>Nenhum item encontrado</h3>
        )}
        <Btnprecompra tema={this.state.tema} />
      </Container>
    );
  }
}

export default Filter;
