import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import style, { createGlobalStyle } from 'styled-components';
import ReactModal from 'react-modal';
import ScrollToTop from '../components/scroll-to-top';
import Navbar from '../components/navbar';
import CategoriesBar from '../components/categories-bar';
import Footer from '../components/footer';
import Router from '../routes';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  :focus {
    outline: none;
  }

  html,
  body {
    height: 100%;
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    font-size: 16px;
  }

  body {
    margin: 0;
    font: 16px 'Roboto', sans-serif;
    overflow-y: scroll;
    color: #333;
    background: #fff;
  }

  button {
    cursor: pointer;
    font: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #ccc;
  }

  .ReactModal__Overlay {
    z-index: 1500;
  }

  .ReactModal__Content {
    max-width: 800px;
    margin: auto;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 300;

    &:before {
      font-size: 42px;
      color: #2c2c2c;
    }
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }
`;

const Main = style.main`
  padding: 30px 40px;
  position: relative;
  z-index: 0;
`;

ReactModal.setAppElement('#root');

const App = () => (
  <BrowserRouter>
    <ScrollToTop>
      <GlobalStyle />
      <Navbar />
      <CategoriesBar />
      <Main>
        <Router />
      </Main>
      <Footer />
    </ScrollToTop>
  </BrowserRouter>
);

export default App;
