import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import style from 'styled-components';
import QRScanner from '../qr-scanner';
import QRImage from '../../images/qr-code.svg';
import Close from '../close';

const QRButton = style.button`
  border: none;
  color: #fff;
  background: transparent;
  border-radius: 4px;
  padding: 3px;
  padding-top: 5px;
  margin-top: 2px;
  margin: 2px;
  margin-left: 7px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3)
  }
`;

class QRModal extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      redirect: false,
      to: ''
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleScan = this.handleScan.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleScan(url) {
    console.log(url);
    this.props.history.push(url);
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <QRButton onClick={this.handleOpenModal}>
          <img src={QRImage} alt="QR Code" />
        </QRButton>
        <ReactModal isOpen={this.state.showModal} contentLabel="QRCode Modal">
          <div style={{ textAlign: 'right' }}>
            <Close onClick={this.handleCloseModal} />
          </div>
          <div style={{ maxWidth: '300px', margin: '15px auto' }}>
            <QRScanner onScan={this.handleScan} />
          </div>
        </ReactModal>
      </>
    );
  }
}

export default withRouter(QRModal);
