import React, { Component } from 'react';
import style from 'styled-components';
import ImgTopo from '../../images/topo_promocao.png';
import ImgMeio from '../../images/desconto.png';
import ImgFooter from '../../images/footer.png';
import api from '../../api';

class Promocao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      email: '',
      tel: '',
      nasc: '',
      cidade: '',
      estado: ''
    };
    this.handleNome = this.handleNome.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleTel = this.handleTel.bind(this);
    this.handleNasc = this.handleNasc.bind(this);
    this.handleCidade = this.handleCidade.bind(this);
    this.handleEstado = this.handleEstado.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNome(valor) {
    this.state.nome = valor.target.value;
  }
  handleEmail(valor) {
    this.state.email = valor.target.value;
  }
  handleTel(valor) {
    this.state.tel = valor.target.value;
  }
  handleNasc(valor) {
    this.state.nasc = valor.target.value;
  }
  handleCidade(valor) {
    this.state.cidade = valor.target.value;
  }
  handleEstado(valor) {
    this.state.estado = valor.target.value;
  }

  handleSubmit() {
    api
      .get('/promocao/', {
        params: {
          nome: this.state.nome,
          email: this.state.email,
          tel: this.state.tel,
          nasc: this.state.nasc,
          cidade: this.state.cidade,
          estado: this.state.estado
        }
      })
      .then(res => {
        alert(res.data.message);
      });
  }

  render() {
    const Divzona = style.div`
    padding-bottom: 30px;
    position: relative;
    z-index: 0;`;
    const PromocaoTopo = style.div`
      margin-left: -40px;
      margin-right: -40px;
      align-items: center;

      img {
        width: 100%;
      }

      @media (max-width: 450px) {
        overflow: hidden;

        img{transform: scale(1.7);}
      }

      `;
    const PromocaoInfor = style.div`
      background: #673e91;
      text-align: center;
      margin-top: -30px;
      margin-left: -40px;
      margin-right: -40px;

      img{
        margin-left: 15px;
      }
      @media (max-width: 450px) {
        img{
          width: 250px;
          margin-top: 25px;
        }
      }
    `;
    const PromocaoForm = style.div`
      margin-left: -40px;
      margin-right: -40px;
      margin-top: -50px;
      background: #673e91;
      padding-top: 100px;
      padding-bottom: 100px;

      form {
        align-items: center;
      }
    `;
    const PromocaoFormCenter = style.div`
      max-width: 350px;
      margin: auto;
      color: #fff;
      text-align: center;

      label{
        float: inline-start;
        margin-left: 15px;
        font-size: 15px;
        font-family: sans-serif;
      }

      @media (max-width: 450px){
        label.labelNome{
          margin-left: 55px;
        }
      }
    `;

    const FormNome = style.input`
        width: 345px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid #673e91;
        transition: border 0.5s;

        &:focus {
          transition: border 0.5s;
          border: 3px solid %fff;
        }

        @media (max-width: 450px) {
            width: 250px;
      }
    `;

    const FormDn = style.input`
        width: 130px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid #673e91;

        @media (max-width: 450px) {
          width: 120px;
        }
    `;
    const FormTelefone = style.input`
        width: 195px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid #673e91;
        @media (max-width: 450px) {
          width: 110px;
        }
    `;
    const FormCidade = style.input`
        width: 275px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid #673e91;
        @media (max-width: 450px) {
          width: 180px;
        }
    }
    `;
    const FormUF = style.input`
        width:50px;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 3px solid #673e91;
        @media (max-width: 450px) {
          width: 50px;
        }
    }
    `;
    const BotaoPromo = style.button`
        background: #5cc1ca;
        color: #fff;
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        font-size: 15px;
        border: none;
    }
    `;
    const PromocaoFooter = style.div`
        margin-left: -40px;
        margin-right: -40px;
        margin-top: -10px;
        text-align: center;
        background: #673e91;
        padding-top: 25px;
        padding-bottom: 25px;


        img {
          width: 200px;
          max-width: 100%;
          max-height: 100%;
        }
    }
    `;

    return (
      <Divzona>
        <div style={{ marginTop: '105px' }}>
          <PromocaoTopo>
            <img
              src={ImgTopo}
              alt="Img Topo"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            ></img>
          </PromocaoTopo>
          <PromocaoInfor>
            <img
              src={ImgMeio}
              alt="Img Topo"
              style={{
                maxWidth: '100%',
                maxHeight: '100%'
              }}
            ></img>
          </PromocaoInfor>
          <PromocaoForm>
            <PromocaoFormCenter>
              <div class="promocaoFormCenterInt">
                <label class="labelNome">
                  <b>Nome</b>
                </label>
                <br />
                <FormNome
                  type="text"
                  name="nome"
                  class="formNome"
                  required
                  onChange={this.handleNome}
                />
                <br />
                <label class="labelNome">
                  <b>Email</b>
                </label>
                <br />
                <FormNome
                  type="email"
                  name="email"
                  class="formEmail"
                  required
                  onChange={this.handleEmail}
                />
                <br />
                <div style={{ display: 'inline-flex' }}>
                  <div>
                    <label>
                      <b>Data de nasc.</b>
                    </label>
                    <br />
                    <FormDn
                      type="date"
                      name="nasc"
                      class="formData"
                      required
                      onChange={this.handleNasc}
                    />
                  </div>
                  <div>
                    <label>
                      <b>Telefone</b>
                    </label>
                    <br />
                    <FormTelefone
                      type="tel"
                      name="tel"
                      class="formTelefone"
                      required
                      onChange={this.handleTel}
                    />
                  </div>
                </div>
                <div style={{ display: 'inline-flex' }}>
                  <div>
                    <label>
                      <b>Cidade</b>
                    </label>
                    <br />
                    <FormCidade
                      type="text"
                      name="cidade"
                      class="formCidade"
                      required
                      onChange={this.handleCidade}
                    />
                  </div>
                  <div>
                    <label>
                      <b>UF</b>
                    </label>
                    <br />
                    <FormUF
                      type="text"
                      name="estado"
                      class="formUF"
                      required
                      onChange={this.handleEstado}
                    />
                  </div>
                  <br />
                </div>
              </div>
              <div class="formBotaoCenter">
                <BotaoPromo onClick={this.handleSubmit} class="formBotao">
                  <b>Cadastrar</b>
                </BotaoPromo>
              </div>
            </PromocaoFormCenter>
          </PromocaoForm>
          <PromocaoFooter>
            <img src={ImgFooter} alt="Footer" />
          </PromocaoFooter>
        </div>
      </Divzona>
    );
  }
}

export default Promocao;
