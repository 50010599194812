import React from 'react';
import style, { css } from 'styled-components';

const Text = style.h1`
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 2rem;



  ${props =>
    props.small &&
    css`
      font-weight: 400;
      font-size: 1.2rem;
    `}
`;
const Cod = style.div`
  padding: 3px 10px;
  position: relative;
  font-weight: normal;
  max-width: 80px;
  text-align: center;
  top: 8px;
  color: rgb(44, 44, 44);
  background: rgb(233, 232, 232) none repeat scroll 0% 0%;
  border-radius: 100px;
  font-size: 10px;
`;

const ProductName = ({ children, code, ...props }) => (
  <Text {...props}>
    <span>{children}</span>
    <br />
    {code && <Cod>Cód.: {code}</Cod>}
  </Text>
);

export default ProductName;
